import React, { Component } from "react";
import {Alert, Button, Col, Input, InputGroup, ModalBody, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import configData from '../config.json';


export default class FormAussteller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artnr: null,
            aussteller: null,
            unverkaufbar: null,
            unverkaufbarinfo: null,
            vk: '',
            infos: '',
            error: '',
            errorUnverkaufbar: ''
        }
        this.token = null;

        this.handleChange = this.handleChange.bind(this);
        this.handleAddSub = this.handleAddSub.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);

    }

    _fetchData = async () => {
        //console.log('_fetchData', this.state);
        var body = '';

        body += `&params[artnr]=` + this.state.artnr;
        body += `&params[filiale]=` + sessionStorage.getItem('filiale');
        body += `&params[aussteller]=` + this.state.aussteller;
        body += `&params[ausstellerinfo]=` + this.state.infos;        
        body += `&params[unverkaufbar]=` + this.state.unverkaufbar;        
        body += `&params[unverkaufbarinfo]=` + this.state.unverkaufbarinfo;        
        body += `&params[vk]=` + this.state.vk;        
        //console.log('_fetchData', body);
        
        body += "&token=" + this.token;

        const response = await fetch(configData.SERVER_URL + 'aussteller', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: body,
        });
        const responseJson = await response.json();
        if(responseJson.status === true) {
            this.props.element.datStatus = '5';
            this.props.element.ausAussteller = this.state.aussteller;
            this.props.element.ausAusstellerUnverkaufbar = this.state.unverkaufbar; 
            this.props.element.ausAusstellerUnverkaufbarInfo = this.state.unverkaufbarinfo; 
            this.props.element.ausVk = this.state.vk;
            this.props.element.ausInfo = this.state.infos;
            this.onSubmit();
        } else {
            this.onClickExit();
        }
    }

    componentDidMount() {
        this.token = this.props.token;
        this.setState({
            artnr: this.props.element.artnr,
            aussteller: this.props.element.ausAussteller,
            unverkaufbar: this.props.element.ausAusstellerUnverkaufbar,
            unverkaufbarinfo: this.props.element.ausAusstellerUnverkaufbarInfo,
            vk: this.props.element.ausVk,
            infos: this.props.element.ausInfo
        })
    }

    onClickSubmit() {
        var unverkaufbarinfo;
        var uvlength = 0;
        //console.log("X",this.state.unverkaufbar, this.state.unverkaufbarinfo);
        if(this.state.unverkaufbarinfo) {
            unverkaufbarinfo = this.state.unverkaufbarinfo;
            uvlength = unverkaufbarinfo.length;
        } else {
            unverkaufbarinfo = this.state.unverkaufbarinfo;
            uvlength = 0;            
        }
        //console.log("Y",unverkaufbarinfo, uvlength);

        if(this.state.unverkaufbar > 0 && (unverkaufbarinfo === null || uvlength < 6)) {
            this.setState({errorUnverkaufbar: <Alert color="danger">Warum ist der Artikel nicht verkaufbar?</Alert>});
        } else {
            this._fetchData();
        }
    }

    onClickExit() {
        sessionStorage.clear();
        window.location.reload(false);
    }    

    onSubmit() {
        this.props.toggle();
    }

    handleChange(event) {
        //console.log(typeof event.target.value);
        var X = this.state.aussteller;
        if(typeof event.target.value === 'string' && (event.target.name === 'infos' || event.target.name === 'unverkaufbarinfo')) {
            X = event.target.value;
        } else if(event.target.value >= 0) {
            X = event.target.value;
        } else {
            X = 0;
        }
        this.setState({[event.target.name]: X});
    }

    handleAddSub(event) {
        //console.log(event.target.dataset.name);
        var MAX = 999;
        var MIN = 0;
        if(event.target.dataset.name === 'aussteller') {
            MAX = this.props.element.bestandMg;
            MIN = this.state.unverkaufbar;
        } else if(event.target.dataset.name === 'unverkaufbar') {
            MAX = this.state.aussteller;
            MIN = 0
        }
        //console.log(MAX);
        var X = event.target.dataset.now;
        if(event.target.dataset.typ === 'sub' && X > 0 && X > MIN) {
            X = 1 * X - 1;
        } else if(event.target.dataset.typ === 'add' && X >= 0 && X < MAX) {
            X = 1 * X + 1;
        }
        this.setState({[event.target.dataset.name]: X});
    }

    //http://portal.expert.de/portal/ordersatz/PIS_ordersatz_detail.php?ordersatz=1&s_kundensicht=&wwsauskunft=1&artnr=11112008477

    render() {
        //console.log(this.props.element);

        return (
            <ModalBody >
                {this.state.error}
                <Row>
                    <Col style={{textAlign: "right"}}>(Stand: {this.props.element.lastImport})</Col>
                </Row>
                <Row>
                    <Bestand fildata={this.props.element.filialdata} gesBestandMg={this.props.element.gesBestandMg} gesBestandMgVerk={this.props.element.gesBestandMgVerk} filialid={this.props.element.filialid} />
                </Row>
         
                <Form>
                    <FormGroup row>
                        <Label for="aussteller" md={6}>
                            Austeller
                        </Label>
                        <Col md={6}>
                            <InputGroup>
                                <Button onClick={this.handleAddSub} data-name="aussteller" data-typ="sub" style={{width: "3rem"}} data-now={this.state.aussteller} > - </Button>
                                <Input
                                    name="aussteller"
                                    type="number"
                                    value={this.state.aussteller}
                                    onChange={this.handleChange}
                                    style={{textAlign: "center"}}
                                />
                                <Button onClick={this.handleAddSub} data-name="aussteller" data-typ="add" style={{width: "3rem"}} data-now={this.state.aussteller} > + </Button>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    
                    <FormGroup row>
                        <Col>
                            <InputGroup>
                                <Input
                                    name="infos"
                                    type="textarea"
                                    placeholder="Info Aussteller"
                                    value={this.state.infos}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>


                    <FormGroup row>
                        <Label for="unverkaufbar" md={6}>
                            davon nicht Verkaufbar
                        </Label>
                        <Col md={6}>
                            <InputGroup>
                                <Button onClick={this.handleAddSub} data-name="unverkaufbar" data-typ="sub" style={{width: "3rem"}} data-now={this.state.unverkaufbar}> - </Button>                                    
                                <Input
                                    name="unverkaufbar"
                                    type="number"
                                    value={this.state.unverkaufbar}
                                    onChange={this.handleChange}
                                    style={{textAlign: "center"}}
                                    max={this.state.aussteller}

                                />
                                <Button onClick={this.handleAddSub} data-name="unverkaufbar" data-typ="add" style={{width: "3rem"}} data-now={this.state.unverkaufbar} > + </Button>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    {this.state.errorUnverkaufbar}

                    <FormGroup row>
                        <Col>
                            <InputGroup>
                                <Input
                                    name="unverkaufbarinfo"
                                    type="textarea"
                                    placeholder="Info zum nicht verkaufbaren Artikel"
                                    value={this.state.unverkaufbarinfo}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>


                    <Row>
                        <Col style={{textAlign: "right"}}>(Stand: {this.props.element.lastImport})</Col>
                    </Row>                          
                    <FormGroup row>
                        <Label for="vk" sm={4}>
                            VK Vorschlag Aussteller
                        </Label>
                        <Col sm={8}>
                            <InputGroup>
                                <Input
                                    name="vk"
                                    type="number"
                                    value={this.state.vk}
                                    onChange={this.handleChange}
                                    style={{textAlign: "center"}}
                                    min={0.00}
                                    max={99999.99}
                                    step={0.01}

                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <Button onClick={this.onClickSubmit}>speichern</Button>
                </Form>
            </ModalBody>
        );
    }
}

function Bestand(props) {

    var artElement = [];
    var artElementBez = [];
    var artElementFil = [];
    var artElementBMenge = [];
    var artElementBmengeV = [];
    var artElementAMenge = [];
    var artElementAMengeU = [];

    artElementBez.push(['Ges']);
    artElementFil[0] = 0;
    artElementBMenge.push([props.gesBestandMg]);
    artElementBmengeV.push([props.gesBestandMgVerk]);
    artElementAMenge.push(['']);
    artElementAMengeU.push(['']);

    props.fildata.forEach((item, index) => {
        artElementBez.push([item['filiale']]);
        artElementBMenge.push([item['bestandMg']]);
        artElementBmengeV.push([item['bestandVerkaufbareMg']]);
        artElementAMenge.push([item['bestandAussteller']]);
        artElementAMengeU.push([item['bestandAusstellerUnverkaufbar']]);
        artElementFil[artElementBez.length - 1] = item['filialid'] * 1;
    });

    var artElementBezeichnung = {'0': 'Bestand', '1': 'Best. Verkaufbar', '2': 'Aussteller', '3': 'Ausst. Unverkaufbar'};
    artElement.push([artElementBMenge, artElementBmengeV, artElementAMenge, artElementAMengeU])

    return (<Table striped>
                <thead>
                    <tr>
                        <th></th>
                        {
                            artElementBez.map((dat, index) => {
                                if(sessionStorage.getItem('filiale')*1 === artElementFil[index]) {
                                    return (
                                        <th key={index} style={{textAlign: "center", color: "blue"}}>{dat}</th>
                                    )  
                                } else {
                                    return (
                                        <th key={index} style={{textAlign: "center"}}>{dat}</th>
                                    )  
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody>

                        {
                            artElement[0].map((dat, index) => {
                                return (
                                    <tr key={index}>
                                    <td>{artElementBezeichnung[index]}</td>
                                    {
                                        dat.map((xdat, xindex) => {
                                            if(sessionStorage.getItem('filiale')*1 === artElementFil[xindex]) {
                                                return (
                                                    <td key={xindex} style={{textAlign: "center"}}><strong>{xdat}</strong></td>
                                                )
                                            } else {
                                                return (
                                                    <td key={xindex} style={{textAlign: "center"}}>{xdat}</td>
                                                )
                                            }
                                        })
                                    }
                                    </tr>
                                )  
                            })
                        }                        
                </tbody>
            </Table>);
                                //<Col index={index} style={{textAlign: "center"}}>{dat.filiale}<br/>{dat.bestandMg}<br/>{dat.bestandVerkaufbareMg}<br/>{dat.bestandAussteller}<br/>{dat.bestandAusstellerUnverkaufbar}</Col>

  }

