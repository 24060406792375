import React, {Component} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Card, CardText, Form, FormGroup, Input, Label, Navbar, NavbarText} from 'reactstrap';
import configData from './config.json';

import Home from './pages/Home';
import Aussteller from './pages/Aussteller';
import Verteilung from './pages/Verteilung';

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isUserAuthenticated: false,
      exTools: null,
      pCode: '',
      pError: '',
      token: null
    }

    this.pcode = null;
    this.token = null;
    this.onCodeChange = this.onCodeChange.bind(this);

  }
  
  componentDidUpdate() {
    if(this.pcode && this.pcode.length === 7) {
        this._fetchData();
    }
  }

  onCodeChange(event) {
    let code = event.target.value
    this.pcode = code;
    //console.log(this.pcode);
    this.setState({
        pCode: code,
    });
}


  _fetchData = async () => {
    var body = null;
    if(this.token) {
      //console.log('App Token', this.token);
      body =  "token=" + this.token;
      this.token = null;
    } else if(this.pcode) {
      //console.log('App pCode');
      body = "code=" + this.pcode;
      this.pcode = '';
    }

    if(body !== null) {
      const response = await fetch(configData.SERVER_URL, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },            
          method: 'POST',
          body: body,
      });
      const responseJson = await response.json();
      //console.log('fetchDataApp', responseJson.user);
      this.token = responseJson.user.Ergebnis;
      if(responseJson.user.OK === 'OK') {
        sessionStorage.setItem('exTools', JSON.stringify(responseJson.user));
        sessionStorage.setItem('exFilialen', JSON.stringify(responseJson.filialen));
        sessionStorage.setItem('artGroup', JSON.stringify(responseJson.artGroup));
        this.setState({isUserAuthenticated: true, exTools: responseJson.user});
        if(sessionStorage.getItem('filiale')) {
          this.setState({filiale: sessionStorage.getItem('filiale')});
        } else {
          this.setState({filiale: responseJson.user.MitarbeiterFiliale});
          sessionStorage.setItem('filiale', responseJson.user.MitarbeiterFiliale);
        }
      } else {
        sessionStorage.clear();
        this.setState({pCode:'', pError: 'Ungültig oder Unbekannt!'});
      }
    }
  }

  componentDidMount() {
    if(sessionStorage.getItem('exTools')) {
      const response = sessionStorage.getItem('exTools');
      const responseJson = JSON.parse(response);
      if(responseJson.Ergebnis !== 'undefined') {
        this.token = responseJson.Ergebnis;
      }
    }
    this._fetchData();
  }

  render() {
    if(this.state.isUserAuthenticated) {
      return (
        <BrowserRouter>
          <Routes>
            <Route exact="true" path="/" element={<Home />}/>
            <Route path="/aussteller" element={<Aussteller />}/>
            <Route path="/verteilung" element={<Verteilung />}/>
          </Routes>
        </BrowserRouter>
      );
    } else {
      return (
          <>
          <Navbar color="dark" dark fixed="top">
          <NavbarText>expert Ahaus, Borken, Coesfeld</NavbarText>
          <NavbarText>Anmeldung</NavbarText>
          </Navbar>
          <div className="container" style={{padding: "4rem 20px"}}>
          <Card body>
              <CardText>
                Willkommen bei den expert Ahaus Tools! Bitte melde dich mit deinen Personal Code an!
              </CardText>
              <Form>
              <FormGroup floating>
              <Input
                  onChange={this.onCodeChange}
                  value={this.state.pCode}                
                  id="pcode"
                  name="pcode"
                  placeholder="Personal Code"
                  type="password"
              />
              <Label for="exampleEmail">
                  Personal Code
              </Label>
              </FormGroup>
              </Form>
              <CardText style={{color:'red'}}>{this.state.pError}</CardText>
          </Card>
          </div>
          </>
      );
    }
  }
}
