import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Navbar, NavbarText, Row, Spinner} from 'reactstrap';
import DataFiliale from '../components/DataFiliale'
import configData from '../config.json';

export default class Home extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          isUserAuthenticated: false,
          exTools: null,
          pCode: '',
          filiale: 0,
          filialen: '',
          token: null
        }
    
        this.pcode = null;
        this.token = null;
        this.fetchAddress = '';
        this.fetchBody = '';

        this.onChangeFiliale = this.onChangeFiliale.bind(this);
    
      }

      _fetchData = async () => {
        var body = null;
        if(this.token) {
          //console.log('Home Token');
          body =  "token=" + this.token;
          this.token = null;
        } else if(this.pcode) {
          //console.log('Home pCode');
          body = "code=" + this.pcode;
          this.pcode = null;
        }
    
        if(body !== null) {
          const response = await fetch(configData.SERVER_URL , {
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
              },            
              method: 'POST',
              body: body,
          });
          const responseJson = await response.json();
          //console.log('fetchDataHome', responseJson);
          this.token = responseJson.user.Ergebnis;
          if(responseJson.user.OK === 'OK') {
            sessionStorage.setItem('exTools', JSON.stringify(responseJson.user));
            this.setState({isUserAuthenticated: true, exTools: responseJson.user, token: responseJson.user.Ergebnis});
            if(sessionStorage.getItem('filiale')) {
              this.setState({filiale: sessionStorage.getItem('filiale')});
            } else {
              this.setState({filiale: responseJson.user.MitarbeiterFiliale});
            }            
          } else {
            this.onClickExit();
          }

        }
      }      

    componentDidMount() {
        if(sessionStorage.getItem('exTools')) {
            const response = sessionStorage.getItem('exTools');
            const responseJson = JSON.parse(response);
            //console.log('DidMount Home', responseJson.Ergebnis);
            if(responseJson.Ergebnis !== 'undefined') {
                this.token = responseJson.Ergebnis;
            }
        }
        this._fetchData();
    }

    onChangeFiliale(value) {
      sessionStorage.setItem('filiale', value);
      this.setState({filiale: value});
    }

    onClickExit() {
      sessionStorage.clear();
      window.location.reload(false);
    }

    render() {
        if(this.state.isUserAuthenticated) {
            const filialen = JSON.parse(sessionStorage.getItem('exFilialen'));
            return (
                    <>
                    <Navbar color="info" fixed="top">
                      <NavbarText>{this.state.exTools.MitarbeiterName}</NavbarText>
                      <NavbarText style={{textAlign: "center"}}>expert Tools  ({filialen[this.state.filiale].bezeichnung})</NavbarText>
                      <NavbarText onClick={this.onClickExit}><i className="bi bi-box-arrow-right"></i> Logout</NavbarText>
                    </Navbar>                
                    <Container className="text-center" style={{padding: "5rem 20px"}}>
                      <Row>
                        <Col>
                        </Col>
                        <Col>
                          <DataFiliale onChangeFiliale={this.onChangeFiliale} valueFiliale={this.state.filiale} token={this.state.token} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Link to="/aussteller" className="btn btn-primary btn-lg" style={{height: '6em', width: '8em', verticalAlign: 'middel'}}><br />Aussteller</Link>
                        </Col>
                        <Col>
                          <Link to="/verteilung" className="btn btn-primary btn-lg" style={{height: '6em', width: '8em', verticalAlign: 'middel'}}><br />Bestand<br />Verteilung</Link>
                        </Col>
                      </Row>
                    </Container>
                    </>
            );
        } else {
            return(
              <Spinner
              className="m-5"
              color="primary"
            >
              Loading...
            </Spinner>
            );
        }
    }
}