import React, { useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import FormAussteller from '../forms/FormAussteller'

const ModalAussteller = (props) => {
    const {
      className
    } = props;
  
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const element = props.element;

    //console.log("modals/Aussteller", element);

    let datStatus;
  
    if(element.datStatus === '0') {
        datStatus = '';
    } else {
        datStatus = <i className="bi bi-cone-striped"></i>;
    }

    return (
        <tr onClick={toggle} >
            <td>{element.artnr}</td>
            <td>{element.bezeichnung}</td>
            <td style={{textAlign: "center"}}>{element.bestandMg}</td>
            <td style={{textAlign: "center"}}>{element.bestandAussteller}</td>
            <td style={{textAlign: "center"}}>{element.gesBestandMg}</td>
            <td>{datStatus}</td>
            <Modal fullscreen isOpen={modal} toggle={toggle}  className={className}>
                <ModalHeader toggle={toggle} style={{backgroundColor: 'cornflowerblue'}}>{element.artnr}<br />{element.bezeichnung}</ModalHeader>
                <FormAussteller element={element} token={props.token} toggle={toggle} onUpdateData={props.onUpdateData} />
            </Modal>
        </tr>
    );
}

export default ModalAussteller;