import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';


export default class DataFiliale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filialen: []
        }

        this.onChangeFiliale = this.onChangeFiliale.bind(this);
    }

    componentDidMount() {
        this.setState({filialen: JSON.parse(sessionStorage.getItem('exFilialen'))});
    }

    onChangeFiliale(event) {
        this.props.onChangeFiliale(event.target.value);
    }

    render() {
        //console.log(this.state.filialen);
        return(
            <FormGroup>
                <Input
                    id="selectFiliale"
                    name="filiale"
                    type="select"
                    value={this.props.valueFiliale}
                    onChange={this.onChangeFiliale}
                >
                    {
                    this.state.filialen.map(
                        (element) => {
                            if(element['nr'] > 0) {
                                return(<option key={element['nr']} value={element['nr']}>{element['bezeichnung']}</option>)
                            } else {
                                return (<option key={element['nr']} value="" disabled>Bitte wählen</option>)
                            }
                        })
                    }
                </Input>
            </FormGroup>
        );
    }
}