import React, { Component } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Row, Table } from 'reactstrap';
import ModalAussteller from '../modals/ModalAussteller';
import configData from '../config.json';

export default class AusstellerListe extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
            artikelList: [],
            artikelInfo: {back: false, fwd: false, all: false},
            exTools: null,
            token: null,
            artKlasse: '',
            artSearch: '',
            artStart: 0,
            artLimit: 18
        }

        this.token = null;
        this.artKlasse = '';
        this.artSearch = '';
        this.body = '';
        this.artGroup = null;
        this.onChangeArtKlasse = this.onChangeArtKlasse.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onClickX = this.onClickX.bind(this);
    }

    _fetchData = async () => {
        var body = '';
        var sbody = 'params[filiale]=' + sessionStorage.getItem('filiale');
        var ebody = '';
        
        body += `&params[klasse]=` + this.state.artKlasse;
        if(this.state.artSearch !== '') {
            body += `&params[search]=` + this.state.artSearch;
        }
        body += `&params[start]=` + this.state.artStart;
        body += `&params[limit]=` + this.state.artLimit;

        //console.log('body', body);

        ebody += "&token=" + this.token;

        if(body !== this.body && this.state.artKlasse !== '') {
            this.body = body;
            const response = await fetch(configData.SERVER_URL + 'artikel', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: sbody + body + ebody,
            });
            const responseJson = await response.json();
            //console.log(responseJson.status);
            if(responseJson.status === true) {
                this.setState({ 
                    artikelList: responseJson.data.artikel, 
                    artikelInfo: responseJson.data.info,
                });
            } else {
                this.onClickExit();
            }
        }

    };

    onChangeArtKlasse(event) {
        this.setState({
            artKlasse: event.target.value, 
            artSearch: '',
            artStart: 0,
            artLimit: 18
        });
    }

    onChangeSearch(event) {
        this.setState({artSearch: event.target.value});
    }

    onClickX(event) {
        //console.log('onClickX', event.target.value);
        this.setState({artStart: event.target.value});
    }

    onClickExit() {
        sessionStorage.clear();
        window.location.reload(false);
    }    

    componentDidUpdate() {
       this._fetchData();
    }

    componentDidMount() {
        if(sessionStorage.getItem('exTools')) {
            const response = sessionStorage.getItem('exTools');
            const responseJson = JSON.parse(response);
            //console.log('DidMount AusstellerList', responseJson.Ergebnis);
            if(responseJson.Ergebnis !== 'undefined') {
                this.token = responseJson.Ergebnis;
            }
        }
        this._fetchData();
    }

    render() {
        //console.log('renderInfo', this.state.artikelInfo.back);
        //console.log('renderListe', this.state.artikelList);
        const artGroup =  JSON.parse(sessionStorage.getItem('artGroup'))

        let backButton, fwdButton, infoAll;

        if(this.state.artikelInfo.back !== false) {
            backButton = <Button onClick={this.onClickX} value={this.state.artikelInfo.back}>zurück</Button>;
        } else {
            backButton = <></>;
        }
        if(this.state.artikelInfo.all === false) {
            infoAll = <></>;
        } else {
            infoAll = this.state.artikelInfo.info;
        }
        if(this.state.artikelInfo.fwd !== false) {
            fwdButton = <Button onClick={this.onClickX} value={this.state.artikelInfo.fwd}>weiter</Button>;
        } else {
            fwdButton = <></>;
        }
            return(
                <Container fluid>
                    <Form inline>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Input
                                    id="selectWarengruppe"
                                    name="filiale"
                                    type="select"
                                    onChange={this.onChangeArtKlasse}
                                    defaultValue={this.state.artKlasse}
                                    >
                                    <option>bitte wählen</option>
                                    {
                                        artGroup.map((element, index) => {
                                            return (
                                                <option key={index} value={element.group}>{element.name}</option>
                                                );
                                        })   
                                    }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Input
                                        id="exampleEmail"
                                        name="search"
                                        placeholder="suche"
                                        type="text"
                                        onChange={this.onChangeSearch}
                                        defaultValue={this.state.artSearch}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    <Table hover>
                        <thead>
                            <tr>
                                <th rowSpan='2'>Nr</th>
                                <th rowSpan='2'>Bezeichnung</th>
                                <th colSpan='3' style={{textAlign: "center"}}>Bestand</th>
                                <th rowSpan='2'>&nbsp;</th>
                            </tr>
                            <tr>
                                <th style={{textAlign: "center"}}>Fil</th>
                                <th style={{textAlign: "center"}}>Aus</th>
                                <th style={{textAlign: "center"}}>Ges</th>
                            </tr>                        
                        </thead>
                        <tbody>
                            {
                                this.state.artikelList.map((element, index) => {
                                    return (
                                        <ModalAussteller key={index} element={element} elementFil={element.filialdata} token={this.token} onUpdateData={this._fetchData} />
                                    );
    
                                })
                            }
                        </tbody>
                    </Table>
                        <Row>
                            <Col>{backButton}</Col>
                            <Col style={{textAlign: "center"}}>{infoAll}</Col>
                            <Col style={{textAlign: "right"}}>{fwdButton}</Col>
                        </Row>
                </Container>
            );
        

    }
}
