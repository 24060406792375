import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, NavbarText } from 'reactstrap';
import VerteilungListe from '../components/VerteilungListe';
import configData from '../config.json';

export default class Verteilung extends Component {

    state = {
        isLoggedIn: false,
        exTools: null,
        token: null
    }

    _fetchData = async () => {
        var body = null;
        if(this.token) {
          //console.log('Aussteller Token');
          body =  "token=" + this.token;
          this.token = null;
        } else if(this.pcode) {
          body = "code=" + this.pcode;
          this.pcode = null;
        }
    
        if(body !== null) {
          const response = await fetch(configData.SERVER_URL, {
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
              },            
              method: 'POST',
              body: body,
          });
          const responseJson = await response.json();
          this.token = responseJson.user.Ergebnis;
          //console.log(body, responseJson);
          if(responseJson.user.OK === 'OK') {
            sessionStorage.setItem('exTools', JSON.stringify(responseJson.user));
            this.setState({isUserAuthenticated: true, exTools: responseJson.user});
          } else {
            sessionStorage.clear();
            window.open("/");
          }
        }
      }  

    componentDidMount() {
        if(sessionStorage.getItem('exTools')) {
            const response = sessionStorage.getItem('exTools');
            const responseJson = JSON.parse(response);
            //console.log('DidMount Aussteller', responseJson.Ergebnis);
            if(responseJson.Ergebnis !== 'undefined') {
                this.token = responseJson.Ergebnis;
            }
        }
        this._fetchData();
    }

    render() {
        if(this.state.exTools) {
            //console.log('Aussteller Render', this.state.exTools);
            const filialen = JSON.parse(sessionStorage.getItem('exFilialen'));

            return (
                    <>
                    <Navbar color="info" fixed="top">
                        <NavbarText>{this.state.exTools.MitarbeiterName}</NavbarText>
                        <NavbarText>expert Tools ({filialen[sessionStorage.getItem('filiale')].bezeichnung}) - Bestand</NavbarText>
                        <Link to="/" className='nav-link'>zurück</Link>
                    </Navbar>
                    <Container fluid style={{padding: "4rem 0px 0px 0px"}}>
                    <VerteilungListe />
                    </Container>
                    </>
            );            
        } 
    }
}